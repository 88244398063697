import React, { useState, useEffect, useRef, Fragment } from 'react';
import { useLocation } from 'react-router-dom';
import { Helmet } from 'react-helmet-async';
import Intro from './Intro';
import ProjectSummary from './ProjectSummary';
import Profile from './Profile';
import Footer from 'components/Footer';
import { usePrefersReducedMotion, useRouteTransition } from 'hooks';
import blair3satTexture from 'assets/blair3sat.jpg';
import blair3satTextureLarge from 'assets/blair3sat-large.jpg';
import blair3satTexturePlaceholder from 'assets/blair3sat-placeholder.jpg';
import affmarkTexture from 'assets/affmark.png';
import affmarkTextureLarge from 'assets/affmark-large.png';
import affmarkTexturePlaceholder from 'assets/affmark-placeholder.png';
import mbhsMlTexture from 'assets/mbhsML.png';
import mbhsMlTextureLarge from 'assets/mbhsML-large.png';
import mbhsMlTexturePlaceholder from 'assets/mbhsML-placeholder.png';
import blairLunchTexture from 'assets/blairLunch.png';
import blairLunchTextureLarge from 'assets/blairLunch-large.png';
import blairLunchTexturePlaceholder from 'assets/blairLunch-placeholder.png';
import rocketRunTexture from 'assets/rocketRun.png';
import rocketRunTextureLarge from 'assets/rocketRun-large.png';
import rocketRunTexturePlaceholder from 'assets/rocketRun-placeholder.png';
import peernoteTexture from 'assets/peernote.png';
import peernoteTextureLarge from 'assets/peernote-large.png';
import peernoteTexturePlaceholder from 'assets/peernote-placeholder.png';
import iphone11 from 'assets/iphone-11.glb';
import macbookPro from 'assets/macbook-pro.glb';

const disciplines = ['Developer', 'Data Scientist', 'AI Enthusiast', 'Engineer'];

export default function Home(props) {
  const { status } = useRouteTransition();
  const { hash, state } = useLocation();
  const initHash = useRef(true);
  const [visibleSections, setVisibleSections] = useState([]);
  const [scrollIndicatorHidden, setScrollIndicatorHidden] = useState(false);
  const intro = useRef();
  const projectOne = useRef();
  const projectTwo = useRef();
  // const projectThree = useRef();
  // const projectFour = useRef();
  // const projectFive = useRef();
  // const projectSix = useRef();
  const about = useRef();
  const prefersReducedMotion = usePrefersReducedMotion();

  useEffect(() => {
    const revealSections = [intro, projectOne, projectTwo, about];

    const sectionObserver = new IntersectionObserver((entries, observer) => {
      entries.forEach(entry => {
        if (entry.isIntersecting) {
          const section = entry.target;
          observer.unobserve(section);
          if (visibleSections.includes(section)) return;
          setVisibleSections(prevSections => [...prevSections, section]);
        }
      });
    }, { rootMargin: '0px 0px -10% 0px' });

    const indicatorObserver = new IntersectionObserver(([entry]) => {
      setScrollIndicatorHidden(!entry.isIntersecting);
    }, { rootMargin: '-100% 0px 0px 0px' });

    revealSections.forEach(section => {
      sectionObserver.observe(section.current);
    });

    indicatorObserver.observe(intro.current);

    return () => {
      sectionObserver.disconnect();
      indicatorObserver.disconnect();
    };
  }, [visibleSections]);

  useEffect(() => {
    const hasEntered = status === 'entered';
    const supportsNativeSmoothScroll = 'scrollBehavior' in document.documentElement.style;
    let scrollObserver;
    let scrollTimeout;

    const handleHashchange = (hash, scroll) => {
      clearTimeout(scrollTimeout);
      const hashSections = [intro, projectOne, projectTwo, about];
      const hashString = hash.replace('#', '');
      const element = hashSections.filter(item => item.current.id === hashString)[0];
      if (!element) return;
      const behavior = scroll && !prefersReducedMotion ? 'smooth' : 'instant';
      const top = element.current.offsetTop;

      scrollObserver = new IntersectionObserver((entries, observer) => {
        const [entry] = entries;
        if (entry.isIntersecting) {
          scrollTimeout = setTimeout(() => {
            element.current.focus();
          }, prefersReducedMotion ? 0 : 400);
          observer.unobserve(entry.target);
        }
      }, { rootMargin: '-20% 0px -20% 0px' });

      scrollObserver.observe(element.current);

      if (supportsNativeSmoothScroll) {
        window.scroll({
          top,
          left: 0,
          behavior,
        });
      } else {
        window.scrollTo(0, top);
      }
    };

    if (hash && initHash.current && hasEntered) {
      handleHashchange(hash, false);
      initHash.current = false;
    } else if (!hash && initHash.current && hasEntered) {
      window.scrollTo(0, 0);
      initHash.current = false;
    } else if (hasEntered) {
      handleHashchange(hash, true);
    }

    return () => {
      clearTimeout(scrollTimeout);
      if (scrollObserver) {
        scrollObserver.disconnect();
      }
    };
  }, [hash, state, prefersReducedMotion, status]);

  return (
    <Fragment>
      <Helmet>
        <title>Vijay Shanmugam | Student + Developer</title>
        <meta
          name="description"
          content="Portfolio of Vijay Shanmugam – a designer, full-stack developer, and data scientist focused on developing scalable solutions to the largest problems."
        />
        <link rel="prefetch" href={iphone11} as="fetch" crossorigin="" />
        <link rel="prefetch" href={macbookPro} as="fetch" crossorigin="" />
      </Helmet>
      <Intro
        id="intro"
        sectionRef={intro}
        disciplines={disciplines}
        scrollIndicatorHidden={scrollIndicatorHidden}
      />
      <ProjectSummary
        id="blair3sat"
        sectionRef={projectOne}
        visible={visibleSections.includes(projectOne.current)}
        index={1}
        title="blair3sat"
        description="Developing a small satellite to measure electron density in the ionosphere."
        buttonText="View Website"
        buttonLink="https://www.blair3sat.org"
        model={{
          type: 'laptop',
          alt: 'The blair3sat Landing Page',
          textures: [
            {
              src: blair3satTexture,
              srcSet: `${blair3satTexture} 800w, ${blair3satTextureLarge} 1440w`,
              placeholder: blair3satTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="mbhsml"
        sectionRef={projectTwo}
        visible={visibleSections.includes(projectTwo.current)}
        index={2}
        title="MBHS ML Club"
        description="Co-founded the machine learning club at Montgomery Blair High School."
        buttonText="View Website"
        buttonLink="https://ml.mbhs.edu"
        model={{
          type: 'laptop',
          alt: 'The MBHS Machine Learning Club Landing Page',
          textures: [
            {
              src: mbhsMlTexture,
              srcSet: `${mbhsMlTexture} 800w, ${mbhsMlTextureLarge} 1440w`,
              placeholder: mbhsMlTexturePlaceholder,
            },
          ],
        }}
      />
      {/* <ProjectSummary
        id="affmark"
        sectionRef={projectThree}
        visible={visibleSections.includes(projectThree.current)}
        index={3}
        title="AffMark"
        description="A marketplace for brands to purchase sponsored posts from influencers."
        buttonText="View Website"
        buttonLink="https://www.affmark.shop"
        model={{
          type: 'laptop',
          alt: 'The AffMark Landing Page',
          textures: [
            {
              src: affmarkTexture,
              srcSet: `${affmarkTexture} 800w, ${affmarkTextureLarge} 1440w`,
              placeholder: affmarkTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="rocketrun"
        sectionRef={projectFour}
        visible={visibleSections.includes(projectFour.current)}
        index={4}
        title="Rocket Run"
        description="A 3D game where you pilot a missile through a silo while avoiding barriers."
        buttonText="View Website"
        buttonLink="https://rocket-run.vijayrs.com"
        model={{
          type: 'laptop',
          alt: 'The Rocket Run website.',
          textures: [
            {
              src: rocketRunTexture,
              srcSet: `${rocketRunTexture} 800w, ${rocketRunTextureLarge} 1440w`,
              placeholder: rocketRunTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="blairlunch"
        sectionRef={projectFive}
        visible={visibleSections.includes(projectFive.current)}
        index={5}
        title="Blair Lunch"
        description="Created a site to view lunch activities at Montgomery Blair High School."
        buttonText="View Website"
        buttonLink="https://www.blairlunch.com"
        model={{
          type: 'laptop',
          alt: 'The blairlunch.com website.',
          textures: [
            {
              src: blairLunchTexture,
              srcSet: `${blairLunchTexture} 800w, ${blairLunchTextureLarge} 1440w`,
              placeholder: blairLunchTexturePlaceholder,
            },
          ],
        }}
      />
      <ProjectSummary
        id="peernote"
        sectionRef={projectSix}
        visible={visibleSections.includes(projectSix.current)}
        index={6}
        title="PeerNote"
        description="Developed a site for students to share resources with their classmates."
        buttonText="View Website"
        buttonLink="https://peernote.co"
        model={{
          type: 'laptop',
          alt: 'The PeerNote website.',
          textures: [
            {
              src: peernoteTexture,
              srcSet: `${peernoteTexture} 800w, ${peernoteTextureLarge} 1440w`,
              placeholder: peernoteTexturePlaceholder,
            },
          ],
        }}
      /> */}
      <Profile
        sectionRef={about}
        visible={visibleSections.includes(about.current)}
        id="about"
      />
      <Footer />
    </Fragment>
  );
}
